export enum RingFinishEnum {
  Silver = 'silver',
  Black = 'black',
  BrushedSilver = 'brushed-silver',
  Stealth = 'stealth',
  Titanium = 'titanium',
  Gold = 'gold',
  Rose = 'rose',
  RoseGold = 'rose-gold',
}
